import React, { useState, useRef } from 'react';
import { setDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import StylizedButton, { size } from '../stuff/stylizedButton';

function RSVPForm() {
    const firstName = useRef(null);
    const lastName = useRef(null);
    const attendingYesRef = useRef(null);
    const attendingNoRef = useRef(null);
    const side = useRef(null);
    const additionalInfo = useRef(null);
    const [numGuests, setNumGuests] = useState(0);
    const [guests, setGuests] = useState([]);

    const currentUser = auth.currentUser;
    const userID = currentUser.uid;

  
    const handleNumGuestsChange = (e) => {
      const count = Number(e.target.value);
      setNumGuests(count);
  
      // Create a new array of guests for the new count.
      const newGuests = [];
      for (let i = 0; i < count; i++) {
        newGuests.push({ firstName: '', lastName: '' });
      }
  
      // Set the guests state to the new array.
      setGuests(newGuests);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(attendingYesRef)
      const formData = {
        firstName: firstName.current.value,
        lastName: lastName.current.value,
        attending: attendingYesRef.current.checked ? 'yes' : 'no',
        side: side.current.value,
        additionalGuests: guests,
        additionalInfo: additionalInfo.current.value,
        guestTotal: guests.length + 1,
        recorded: false
      };
  
      console.log(formData.attending)
      try {
        await setDoc(doc(db, 'rsvps', userID), formData);
        alert('RSVP submitted successfully!');
      } catch (error) {
        console.error('Error submitting RSVP: ', error);
        alert('Failed to submit RSVP. Please try again.');
      }
    };
  
    const labelStyle = {
      color: 'white'
    };
  
    return (
      <form onSubmit={handleSubmit} style={{backgroundColor: 'black'}}>
        <label style={labelStyle}>First Name:</label>
        <input type="text" ref={firstName} required />
        <br/>
        <label style={labelStyle}>Last Name:</label>
        <input type="text" ref={lastName} required />
      <br/>
        <label style={labelStyle}>Attending:</label>
        <div>
        <label style={labelStyle}><input type="radio" ref={attendingYesRef} value="yes" name="attending" required /> Yes</label>
<label style={labelStyle}><input type="radio" ref={attendingNoRef} value="no" name="attending" /> No</label>
      
        </div>
  <br/>
        <label style={labelStyle}>Representing:</label>
        <select ref={side} required>
          <option value="groom">Groom</option>
          <option value="bride">Bride</option>
        </select>
  <br/>
        <label style={labelStyle}>Number of additional guests:</label>
        <input type="number" value={numGuests} onChange={handleNumGuestsChange} min="0" />

        {guests.map((guest, index) => (
          <div key={index}>
            <label style={labelStyle}>Guest {index + 1} First Name:</label>
            <input
            required
              type="text"
              value={guest.firstName}
              onChange={(e) => {
                const newGuests = [...guests];
                newGuests[index].firstName = e.target.value;
                setGuests(newGuests);
              }}
            />
            <label style={labelStyle}> Last Name:</label>
            <input
            required
              type="text"
              value={guest.lastName}
              onChange={(e) => {
                const newGuests = [...guests];
                newGuests[index].lastName = e.target.value;
                setGuests(newGuests);
              }}
            />
          </div>
        ))}
  <br/>
        <label style={labelStyle}>Any food allergies or accommodations:</label>
        <textarea ref={additionalInfo}></textarea>
  <br/>
        <StylizedButton type="submit" buttonSize={size.Small}>Submit</StylizedButton>
      </form>
    );
  }
  
  export default RSVPForm;
  
  