import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Navbar from './Navbar';

const Banner = () => {
  const theme = useTheme();

  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 2,
      paddingLeft: 4, // Add left padding
      paddingRight: 4, // Add right padding
      backgroundColor: theme.palette.primary.main,
      boxShadow: 2,
    }}
   >
      <Typography variant="subtitle2" color="white"
        sx={{ 
          fontSize: '2rem',
          '@media (max-width:1040px)': {
            fontSize: '2rem',
          },
          '@media (max-width:800px)': {
            fontSize: '1.5rem',
          },
          '@media (max-width:550px)': {
            fontSize: '1rem',
          },
        }}
      >
        October 13, 2023
      </Typography>
      <Box display="flex" flexDirection='row' flexWrap='wrap'>
        <Typography variant="h4" color="white" 
          sx={{ 
            marginTop: 1, 
            fontSize: '4rem',
            '@media (max-width:1040px)': {
              fontSize: '3rem',
            },
            '@media (max-width:800px)': {
              fontSize: '2rem',
            },
            '@media (max-width:550px)': {
              fontSize: '1.5rem',
            }, paddingRight: '1rem'
          }}
        >
          SABRINA HERRERO
        </Typography>
        <Typography variant="h4" color="white" 
          sx={{ 
            marginTop: 1, 
            fontSize: '4rem',
            '@media (max-width:1040px)': {
              fontSize: '3rem',
            },
            '@media (max-width:800px)': {
              fontSize: '2rem',
            },
            '@media (max-width:550px)': {
              fontSize: '1.5rem',
            }, paddingRight: '1rem'
          }}
        >
          &
        </Typography>
        <Typography variant="h4" color="white" 
          sx={{ 
            marginTop: 1,
            fontSize: '4rem',
            '@media (max-width:1040px)': {
              fontSize: '3rem',
            },
            '@media (max-width:800px)': {
              fontSize: '2rem',
            },
            '@media (max-width:550px)': {
              fontSize: '1.5rem',
            }
          }}
        >
          JESUS PALOS
        </Typography>
      </Box>
      
      <Navbar/>
    </Box>
  );
};

export default Banner;
