import { Box, Link, Typography, ImageListItem } from "@mui/material";
import StylizedButton, { size } from "../stuff/stylizedButton";
import React from "react";
import './registryCard.css'

function RegistryCard(props) {
    const isClaimedByCurrentUser = props.user === props.item.claimedBy;
    const isAvailable = !props.item.claimedBy;

    return (
        <ImageListItem style={{ backgroundColor: 'white', overflow: 'hidden', borderRadius: '5px' }} key={props.item.imageLink}>
            <center
                style={{
                    position: 'relative', // added this line
                    borderBottom: 'solid #DBD7D7 1px',
                    maxHeight: '201px',
                    boxShadow: "0px 6px 6px -5px #A0A0A0"
                }}
            >
                <img
                    src={props.item.imageLink}
                    alt={props.item.title}
                    loading="lazy"
                    style={{ height: '200px', maxWidth: '100%' }}
                />
                {isClaimedByCurrentUser && (
                    <div className="overlay">
                        <div className="text">Thank you</div>
                    </div>
                )}
            </center>
            {!isAvailable && !isClaimedByCurrentUser && (
                <div className="overlay" style={{ height: '100%', width: '100%', position: 'absolute' }}></div>
            )}
            <Box sx={{ padding: '15px', wordWrap: 'break-word', }}>
                <Typography variant="h4" color="#000"
                    sx={{
                        marginTop: '1rem',
                        fontSize: '1.5rem',
                        textTransform: "uppercase",
                    }}
                >
                    {props.item.title}
                </Typography>
                <Typography variant="h4" color="#000"
                    sx={{ fontSize: '2rem' }}
                >
                    ${props.item.price}
                </Typography>
                <Typography variant="h4" color="#000"
                    sx={{ fontSize: '1.2rem' }}
                >
                    <Link href={props.item.link} target="_blank" rel="noreferrer" color="inherit">Link</Link>
                </Typography>
                {isAvailable || isClaimedByCurrentUser ? (
                    <center>
                        <StylizedButton
                            onClick={props.onClick}
                            buttonSize={size.Small}
                            style={{ marginTop: '1rem' }}
                        >
                            {isAvailable ? ("Claim This Gift") : ("Unclaim This Gift")}
                        </StylizedButton>
                    </center>
                ) : null}
            </Box>
        </ImageListItem>
    )
}


export default RegistryCard;