import React, { useEffect, useState, useContext } from 'react';
import { fetchRegistryData } from './fetchRegistry';
import { AuthContext } from '../Nav/AuthContext';
import { claimGiftWithUserId, unclaimGift } from './fetchRegistry';
import { Box, ImageList, Typography, Snackbar, Alert, Skeleton } from '@mui/material';
import RegistryCard from './registryCard';
import FiltersComponent from './FiltersComponent';

function RegistryList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("highToLow");
  const [items, setItems] = useState([])
  const [filterAvailable, setFilterAvailable] = useState(true);
  const [checkedValues, setCheckedValues] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState('success'); // This can be 'success' or 'error'

  const LOWTOHIGH = "lowToHigh";
  const HIGHTOLOW = "highToLow";
  const ALPHABETICAL = "aToZ";

  const { currentUser } = useContext(AuthContext);
  const userID = currentUser.uid;

  const handleChangePriceFilter = (event) => {
    const { value, checked } = event.target;
    if (checked && !checkedValues.includes(value)) {
      setCheckedValues([...checkedValues, value]);
    } else if (!checked) {
      setCheckedValues(checkedValues.filter(item => item !== value));
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleAvailabilityChange = (value) => {
    switch (value) {
      case 'available':
        setFilterAvailable(true);
        break;
      case 'alreadyPurchased':
        setFilterAvailable(false);
        break;
      case 'noFilter':
        setFilterAvailable(null);
        break;
      default:
        setFilterAvailable(null);

    }
  };

  useEffect(() => {
    const tempItems = [...data]

    let sortedItems = [];
    switch (sortOrder) {
      case HIGHTOLOW:
        sortedItems = tempItems.sort((a, b) => b.price - a.price);
        break;
      case LOWTOHIGH:
        sortedItems = tempItems.sort((a, b) => a.price - b.price);
        break;
      case ALPHABETICAL:
        sortedItems = tempItems.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'zToA':
        sortedItems = tempItems.sort((a, b) => b.title.localeCompare(a.title));
        break;
      default:
        sortedItems = tempItems;
    }

    let filteredItems = sortedItems;
    if (filterAvailable === true) {
      filteredItems = sortedItems.filter(item => item.claimedBy === "");
    } else if (filterAvailable === false) {
      filteredItems = sortedItems.filter(item => item.claimedBy !== "");
    }  // if filterAvailable is null, no filtering is applied


    if (checkedValues.length !== 0) {
      filteredItems = filteredItems.filter(item => {
        const price = item.price;
        return checkedValues.some(range => {
          switch (range) {
            case '<25': return price < 25;
            case '25-50': return price >= 25 && price <= 50;
            case '50-100': return price > 50 && price <= 100;
            case '100-150': return price > 100 && price <= 150;
            case '150-200': return price > 150 && price <= 200;
            case '200+': return price > 200;
            default: return false;
          }
        });
      })
    }

    setItems(filteredItems);
  }, [sortOrder, filterAvailable, data, checkedValues])


  useEffect(() => {
    console.log("RegistryList mounted");
    return () => {
      console.log("RegistryList will unmount");
    };
  }, []);


  useEffect(() => {
    async function fetchData() {
      try {
        const result = await fetchRegistryData();
        setData(result);
        setItems(result);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    debugger;
    return (<div>
      <Skeleton variant="rectangular" width={210} height={118} />
      <Skeleton variant="text" width={210} height={40} />
      <Skeleton variant="text" width={210} /><Skeleton variant="rectangular" width={210} height={118} />
      <Skeleton variant="text" width={210} height={40} />
      <Skeleton variant="text" width={210} /><Skeleton variant="rectangular" width={210} height={118} />
      <Skeleton variant="text" width={210} height={40} />
      <Skeleton variant="text" width={210} />
      </div>);
  }

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleClaim = async (event, itemId) => {
    let update = false;
    if (event === "claim") {
      update = await claimGiftWithUserId(itemId, userID, setSnackbarMessage, setSnackbarOpen, setSnackbarVariant);
    } else {
      update = await unclaimGift(itemId, "", setSnackbarMessage, setSnackbarOpen, setSnackbarVariant);
    }

    if (update) {
      const result = await fetchRegistryData();
      setData(result);
    }
  }

  const Sort = () => {
    return (
      <select value={sortOrder} onChange={handleSortChange}>
        <option value="" disabled>Select Sort Order</option>
        <option value={LOWTOHIGH}>Sort Low to High</option>
        <option value={HIGHTOLOW}>Sort High to Low</option>
        <option value={ALPHABETICAL}>Sort A to Z</option>
      </select>
    );
  }

  const clearPriceFilter = () => setCheckedValues([]);


  const getOnClickFunctionForItem = (item) => {
    if (item.claimedBy !== "") {
      return () => handleClaim("unclaim", item.id);
    } else {
      return () => handleClaim("claim", item.id);
    }
  }

  return (
    <div>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px' }}>
        <Typography color='white' sx={{ textTransform: "uppercase", paddingRight: '5px' }}>Thank You Family and friends for contributing to our new life together. <br />Our Address is <b>6601 Dublin Blvd. Apt 406 Dublin, Califorina 94568</b></Typography>
        {Sort()}
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <FiltersComponent
          checkedValues={checkedValues}
          handleChangePriceFilter={handleChangePriceFilter}
          clearPriceFilter={clearPriceFilter}
          handleAvailabilityChange={handleAvailabilityChange}
        />
        {!loading && ( 
        <ImageList cols={4} gap={10} style={{ marginRight: '30px' }}>
          {items.map(item => (
            <RegistryCard
              key={item.id}
              item={item}
              user={userID}
              isClaimed={item.claimedBy !== ""}
              onClick={getOnClickFunctionForItem(item)}
            />))}
        </ImageList>)}
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarVariant}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RegistryList;


