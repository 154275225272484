import React from 'react';
import { Typography, Divider, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import AvailabilityFilter from './AvailabilityFilter';
import StylizedButton, { size } from '../stuff/stylizedButton';

function FiltersComponent({ checkedValues, handleChangePriceFilter, clearPriceFilter, handleAvailabilityChange }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', marginLeft: '30px', height: '20%', width: '300px', marginTop: '1em', marginBottom: '10px' }}>
            <Typography align='center' style={{ fontWeight: 'bold', fontSize: '1.3rem', marginTop: '30px' }}>AVAILABILITY FILTERS</Typography>
            <Divider variant="middle" sx={{ color: '#DBD7D7' }} />
            <div align='center'>
                <AvailabilityFilter onChange={(value) => handleAvailabilityChange(value)} />
            </div>
            <Typography align='center' style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>PRICE</Typography>
            <Divider variant="middle" sx={{ color: '#DBD7D7' }} />
            <FormGroup style={{ flexDirection: 'column', margin: '10px' }}>
                <FormControlLabel control={<Checkbox color="default" sx={{
                    color: '#870505',
                    '&.Mui-checked': { color: '#870505' }
                }} checked={checkedValues.includes('<25')} onChange={handleChangePriceFilter} value="<25" />} label="<25" />
                <FormControlLabel control={<Checkbox color="default" sx={{
                    color: '#870505',
                    '&.Mui-checked': { color: '#870505' }
                }} checked={checkedValues.includes('25-50')} onChange={handleChangePriceFilter} value="25-50" />} label="25-50" />
                <FormControlLabel control={<Checkbox color="default" sx={{
                    color: '#870505',
                    '&.Mui-checked': { color: '#870505' }
                }} checked={checkedValues.includes('50-100')} onChange={handleChangePriceFilter} value="50-100" />} label="50-100" />
                <FormControlLabel control={<Checkbox color="default" sx={{
                    color: '#870505',
                    '&.Mui-checked': { color: '#870505' }
                }} checked={checkedValues.includes('100-150')} onChange={handleChangePriceFilter} value="100-150" />} label="100-150" />
                <FormControlLabel control={<Checkbox color="default" sx={{
                    color: '#870505',
                    '&.Mui-checked': { color: '#870505' }
                }} checked={checkedValues.includes('150-200')} onChange={handleChangePriceFilter} value="150-200" />} label="150-200" />
                <FormControlLabel control={<Checkbox color="default" sx={{
                    color: '#870505',
                    '&.Mui-checked': { color: '#870505' }
                }} checked={checkedValues.includes('200+')} onChange={handleChangePriceFilter} value="200+" />} label="200+" />
            </FormGroup>
            <StylizedButton style={{ margin: '20px' }} buttonSize={size.ExtraSmall} onClick={clearPriceFilter}>Clear Price Filter</StylizedButton>
        </div>
    );
}

export default FiltersComponent;
