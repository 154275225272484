import React from 'react';
import CountdownTimer from './stuff/CountdownTimer';
import { Typography } from '@mui/material';
import StylizedButton from './stuff/stylizedButton';
import weddingEvent from '../WeddingEvent.ics'
import { useLocation } from 'react-router-dom';


const Main = () => {
  const location = useLocation();
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <CountdownTimer/>
      <Typography color="white" align='center'>
        <h2>Location:</h2> ARISTIDE, FLOWER MOUND
        2701 CORPORATE CIRCLE
        FLOWER MOUND, TEXAS 75028
      </Typography><br/>
      <Typography color="white" align='center'>
        <h2>Time:</h2> FRIDAY, OCTOBER 13, 2023
        AT 5 IN THE EVENING
      </Typography>
      <a href={weddingEvent} download="Jesus and Sabrina's Wedding" target='_blank' style={{maxWidth: '40%', marginTop: '2rem'}}>
        <StylizedButton>Download calendar event</StylizedButton>
      </a> 
     
    </div>
  );
};

export default Main;
