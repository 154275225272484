import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase'

function AddToRegistry() {
  const [formData, setFormData] = useState({
    link: '',
    imageLink: '',
    title: '',
    price: 0,
    claimedBy: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.name === 'price' ? parseFloat(e.target.value) : e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'registry'), formData);
      alert('Item added successfully!');
      setFormData({
        link: '',
        imageLink: '',
        title: '',
        price: 0,
        claimedBy: 'null'
      });
    } catch (error) {
      alert('Error adding document: ', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="link"
        placeholder="Link"
        value={formData.link}
        onChange={handleChange}
      />
      <input
        type="text"
        name="imageLink"
        placeholder="Image Link"
        value={formData.imageLink}
        onChange={handleChange}
      />
      <input
        type="text"
        name="title"
        placeholder="Title"
        value={formData.title}
        onChange={handleChange}
      />
      <input
        type="number"
        name="price"
        placeholder="Price"
        value={formData.price}
        onChange={handleChange}
      />
      <button type="submit">Add to Registry</button>
    </form>
  );
}

export default AddToRegistry;