import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqs from './faq.json';

const FAQ = () => {
  return (
    <div>
      <Typography variant="h3" color="white" align='center' style={{paddingTop: '2rem'}}>
        FAQ
      </Typography>
      <br/>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography fontWeight="bold">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography component="span" dangerouslySetInnerHTML={{ __html: faq.answer }}></Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default FAQ;
