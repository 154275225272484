import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import RegistryList from './RegistryList';
import AddToRegistry from './AddToRegistry';
import CustomAuth from '../Nav/CustomAuth';
import { AuthProvider, AuthContext } from '../Nav/AuthContext';

const Registry = () => {

  const { currentUser } = useContext(AuthContext);
  return (
    <AuthProvider>
      {currentUser ? (
        <>
          <Typography variant="h3" color="white" align='center' style={{ paddingTop: '2rem' }}>
            REGISTRY
          </Typography>
          <Box style={{ backgroundColor: '#450202' }}>
            <RegistryList />
          </Box>
        </>
      ) : (<CustomAuth title={"Registry"} />)}
      {/* <AddToRegistry/> */}
    </AuthProvider>
  )
};
export default Registry;
