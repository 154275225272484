import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

function AvailabilityFilter({ onChange }) {
    const [value, setValue] = useState('available');

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup
                aria-label="availability"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel
                    value="available"
                    control={
                        <Radio
                            color="default"
                            sx={{ '&.Mui-checked': { color: '#870505' } }}
                        />
                    }
                    label="Available"
                />
                <FormControlLabel
                    value="alreadyPurchased"
                    control={
                        <Radio
                            color="default"
                            sx={{ '&.Mui-checked': { color: '#870505' } }}
                        />
                    }
                    label="Already Purchased"
                />
                <FormControlLabel
                    value="noFilter"
                    control={
                        <Radio
                            color="default"
                            sx={{ '&.Mui-checked': { color: '#870505' } }}
                        />
                    }
                    label="No Filter"
                />
            </RadioGroup>
        </FormControl>
    );
}

export default AvailabilityFilter;
