import React, { useContext, useState } from 'react';
import { AuthProvider, AuthContext } from '../Nav/AuthContext';
import LogoutButton from '../stuff/LogoutButton';
import RSVPForm from './RSVPForm';
import CustomAuth from '../Nav/CustomAuth';
import { Box } from '@mui/material';



const RSVP = () => {


  const { currentUser } = useContext(AuthContext);

  return (
    <AuthProvider>
      {currentUser ? (
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%'}}>
          <RSVPForm />
          <br />
          <LogoutButton />
        </Box>
      ) :
        (<CustomAuth title={"RSVP"
        } />)
      }
    </AuthProvider>
  )
};

export default RSVP;
