// useCountdown.js
import { useState, useEffect } from 'react';

export const useCountdown = (targetDate) => {
  const calculateTimeLeft = () => {
    const now = Date.now();
    const diff = targetDate - now;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);

    return {
      expired: diff < 0,
      days,
      hours,
      minutes,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerId);
  }, [targetDate]);

  return timeLeft;
};

// export default useCountdown;  // Here is the export
