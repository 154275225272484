import React from "react";
import {auth, signOut} from '../../firebase';
import StylizedButton, { size } from "./stylizedButton";

function LogoutButton () {
    const handleLogout = async () => {
        try {
            await signOut(auth);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <StylizedButton onClick={handleLogout} buttonSize={size.Small}>Logout</StylizedButton>
    )
}

export default LogoutButton;