import { Button } from "@mui/material";
import React from "react";
const size = {
    ExtraSmall: ".8rem",
    Small: "1rem",
    Medium: "1.5rem", 
    Large: "2rem" 
}

function StylizedButton(props) {

    const style = {
        backgroundColor: "#870505",
        color: "white",
        borderRadius: "5px",
        boxShadow: "0 5px #540303",
        fontSize: props.buttonSize || size.Medium,
        border: "none",
        fontFamily: "'Karma', serif",
        textTransform: "uppercase",
        padding: "2px 20px",

        "&:hover": {
            background: "#450202"
        },
        "&:active": {
            background: "#450202",
            boxShadow: "0 3px #540303",
            transform: "translateY(3px)"
        },
        ...props.style
    }

    return (
        <Button 
            sx={style}
            onClick={props.onClick}
            type={props.type}
        >{props.children}</Button>
    )

}


export {size};
export default StylizedButton;