import { initializeApp } from 'firebase/app';
import { getAuth, signOut, fetchSignInMethodsForEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBRL6ya7K8mFTK86vJg9BBEapMP18jF4jk",
  authDomain: "wedding-website-76908.firebaseapp.com",
  projectId: "wedding-website-76908",
  storageBucket: "wedding-website-76908.appspot.com",
  messagingSenderId: "976260270767",
  appId: "1:976260270767:web:5376a3b9746b0755f5225a"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);
const CLIENT_ID = "976260270767-fle60vm3om0pgi7pcq5vd6kv21lrt6q2.apps.googleusercontent.com"

export {app, auth, db, signOut, CLIENT_ID, fetchSignInMethodsForEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail};