import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';

const StyledLink = styled(RouterLink)`
  color: #fff;
  text-decoration: none;
  margin: 0 1rem;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: red;
  }
`;

const Navbar = () => {
  return (
    <AppBar position="sticky" color="transparent" elevation={0}>
      <Toolbar>
        {/* <RouterLink to="/" style={{ textDecoration: 'none' }}>
          <Typography variant="h6" color="inherit" component="div">
            My App
          </Typography>
        </RouterLink> */}
        <Box
          marginLeft="auto"
          marginRight="auto"
          display="flex"
          justifyContent="center"
        >
          <StyledLink to="/">Main</StyledLink>
          <StyledLink to="/rsvp">RSVP</StyledLink>
          <StyledLink to="/registry">Registry</StyledLink>
          <StyledLink to="/faq">FAQ</StyledLink>
          {/* <StyledLink to="/contact">Contact</StyledLink> */}
          
          {/* <StyledLink to="/timeline">Timeline</StyledLink> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
