import React, { useState } from 'react';
import { useCountdown } from './useCountdown'; // adjust the path as necessary
import {
  Box,
  Typography,
  Container,
  Grid,
} from '@mui/material';

const styles = {
  numbers: {
    fontSize: 128,
    color: '#fff',
    align: 'center'
  },
  captions: {
    fontSize: 40,
    color: '#fff',
    align: 'center'
  },
  padding: {
    paddingTop: 0,
    paddingBottom: 0,
    padding: '4px',
  },
  margin: {
    margin: 0,
  },
  flex: {
    flex: 1,
    alignItems: 'center',
  },
};

const Countdown = () => {
  const targetDate = new Date('Oct 13, 2023 17:00:00').getTime();
  const { expired, days, hours, minutes } = useCountdown(targetDate);

  return (
    <Container m={2} style={{ backgroundColor: '#450202'}}>
      {expired ? (
        <Typography variant="subtitle1" color="#fff">Expired</Typography>
      ) : (
        <Grid container spacing={2} columnCount={3} wrap="nowrap" align='center'>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" style={styles.numbers}>{days}</Typography>
            <Typography variant="caption" align='center' style={styles.captions}>Days</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" style={styles.numbers}>{hours}</Typography>
            <Typography variant="caption" style={styles.captions}>Hours</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" style={styles.numbers}>{minutes}</Typography>
            <Typography variant="caption" style={styles.captions}>Minutes</Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Countdown;
