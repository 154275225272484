import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

export async function fetchRegistryData() {
  const registryCollection = collection(db, 'registry');
  const snapshot = await getDocs(registryCollection);
  const dataList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  return dataList;
}

async function updateRegistryItemWithUserId(itemId, userId, action, setSnackbarMessage, setSnackbarOpen, setSnackbarVariant) {
  const itemRef = doc(db, 'registry', itemId);
  try {
    await setDoc(itemRef, { claimedBy: userId }, { merge: true });

    let message = action === 'claim' ? "Item has been claimed" : "Item has been unclaimed";
    setSnackbarMessage(message);
    setSnackbarVariant('success'); // Set to success
    setSnackbarOpen(true);

    return true;
  } catch (error) {
    setSnackbarMessage("Error updating this item");
    setSnackbarVariant('error'); // Set to error
    setSnackbarOpen(true);
    console.error("Error claiming/unclaiming: ", error);
    return false;
  }
}


export async function claimGiftWithUserId(itemId, userId, setSnackbarMessage, setSnackbarOpen, setSnackbarVariant) {
  return updateRegistryItemWithUserId(itemId, userId, 'claim', setSnackbarMessage, setSnackbarOpen, setSnackbarVariant);
}

export async function unclaimGift(itemId, user = '', setSnackbarMessage, setSnackbarOpen, setSnackbarVariant) {
  return updateRegistryItemWithUserId(itemId, '', 'unclaim', setSnackbarMessage, setSnackbarOpen, setSnackbarVariant);
}



