import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Main from './components/Main';
import RSVP from './components/RSVP/RSVP';
import FAQ from './components/FAQ/FAQ';
import Registry from './components/Registry/Registry';
import Banner from './components/Nav/Banner';
import './App.css'
import { AuthProvider } from './components/Nav/AuthContext';


const theme = createTheme({
  typography: {
    fontFamily: "'Karma', serif",
  },
  palette: {
    primary: {
      main: '#131315',
    },
    background: {
      default: '#0A0909',
    },
  },
});



function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
        <Banner/>      
        <Container  >
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/rsvp" element={<RSVP />} />
            <Route path="/registry" element={<Registry />} />
            <Route path="/faq" element={<FAQ />} />
            {/* <Route path="/contact" element={<Contact />} /> */}
            
            {/* <Route path="/timeline" element={<Timeline />} /> */}
          </Routes>
        </Container>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
