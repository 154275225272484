import React, {useEffect, useRef, useState} from 'react';
import {  fetchSignInMethodsForEmail, auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail} from '../../firebase';
import StylizedButton, { size } from '../stuff/stylizedButton';
import batEmbelishment from '../../images/bat-embelish.png'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function CustomAuth(props) {
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null)

    const handleLogIn = async (e) => {
        try {
            console.log('we blur')
            const UserCreds = await signInWithEmailAndPassword(auth, email, password);
            console.log(UserCreds)
        } catch (err) {
            setError(err);
        }
    }

    const handleSignUp = async (e) => {
        try {
            if(password.length < 6){
                alert("Please input a password more than 5 characters")
                return;
            }
            const UserCreds = await createUserWithEmailAndPassword(auth, email, password);
            console.log(UserCreds)
        } catch (err) {
            if(err.code === "auth/email-already-in-use")
                alert("user exists, please sign in")
            setError(err);
        }
    }

    const resetPasswordEmail = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (err) {
            if(err.code === "auth/missing-email")
                alert("Please provide an email before resetting password")
            setError(err);
        }
    }

    return (
        <>
        <Box
            display="flex"
            alignItems="center"
            marginTop="2rem"
            flexDirection="column"
        >
            <Typography variant="h1" color="#e3dfce" 
                sx={{ 
                marginTop: 1, 
                fontSize: '5rem',
                }}
            >
                {props.title}
            </Typography>
            <img style= {{maxWidth:"100%"}} alt='' src={batEmbelishment}/>
            <Typography variant="h4" color="#e3dfce" 
                sx={{ 
                marginTop: '2rem', 
                fontSize: '2rem',
                }}
            >
                Log In To See {props.title}
            </Typography>

            <Box display="flex"
            alignItems="center"
            flexDirection="row"
            width="50%"
            justifyContent="space-evenly"
            marginTop='2rem'>
                <Box display="inline-flex"
                flexDirection="column">
                    <label style={{color: 'white'}} >Email:</label>
                    <input type='text' required onChange={(e) => {
                        setEmail(e.currentTarget.value);
                    }}/>
                </Box>
                <Box display="inline-flex"
                flexDirection="column"> 
                    <label style={{color: 'white'}}>Password:</label>
                    <input type='password' required onChange={(e) => {
                        setPassword(e.currentTarget.value);
                    }}/>
                </Box>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                marginTop="3rem"
                flexDirection="row"
                justifyContent='space-between'
                width="40%"
                minWidth='400px'
                marginBottom='4rem'
            > 
                <StylizedButton buttonSize={size.Small} onClick={handleSignUp}>Sign Up</StylizedButton>
                <StylizedButton buttonSize={size.Small} onClick={handleLogIn}>Sign In</StylizedButton>
                <StylizedButton buttonSize={size.Small} onClick={resetPasswordEmail}>Password Reset</StylizedButton>

            </Box>
        </Box>    
        </>
    );
}

export default CustomAuth;
